<template>
  <div>
    <our-team-header />
    <v-container>
      <v-row justify="center">
        <v-col
          cols="12"
          sm="4"
          data-aos="zoom-in"
          data-aos-anchor-placement="bottom-bottom"
          v-for="(leader, index) in team"
          :key="leader.OurTeamID"
          v-show="overlay"
        >
          <div
            :style="{
              backgroundImage: 'url(\'' + image + '\')',
              backgroundSize: '92%',
              backgroundPosition: 'bottom',
              width: '100%',
              paddingBottom: '14px',
            }"
          >
            <img
              contain
              :src="origin + leader.photos[0]"
              style="
                position: relative;
                z-index: 2;
                width: 80%;
                max-height: 100%;
                border-radius: 50%;
                margin-left: 10%;
                top: 0px;
                left: 0px;
              "
              justify="center"
              @load="oload"
            />
          </div>

          <div style="text-align: center">
            <h1>{{ leader.firstName + " " + leader.lastName }}</h1>
            <h3>{{ leader.position }}</h3>
            <v-btn
              icon
              class="md-4 pa-4"
              :href="leader.Facebook"
              target="_blank"
              v-show="leader.Facebook != '0'"
            >
              <v-icon color="#005677"> mdi-facebook </v-icon>
            </v-btn>
            <v-btn
              icon
              class="md-4 pa-1 mx-4"
              :href="leader.instagram"
              v-show="leader.instagram != '0'"
              target="_blank"
            >
              <v-icon dark color="#005677"> mdi-instagram </v-icon>
            </v-btn>
            <v-btn
              icon
              class="md-4 pa-4"
              :href="leader.Linkedin"
              v-show="leader.Linkedin != '0'"
              target="_blank"
            >
              <v-icon color="#005677"> mdi-linkedin </v-icon>
            </v-btn>
          </div>
          <div style="text-align: center">
            <v-btn color="#005677" outlined @click="showServices(index)">
              {{ teammore[lang] }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <b-modal id="service-modal" :hide-footer="true" size="l">
      <b-overlay :show="show" style="text-align: center">
        <v-row style="width: 100%" justify="center">
          <v-col>
            <div
              :style="{
                backgroundImage: 'url(\'' + image + '\')',
                backgroundSize: '72%',
                backgroundPosition: 'bottom',
                width: '100%',
              }"
            >
              <img
                contain
                :src="origin + selectedleader.photos[0]"
                style="
                  position: relative;
                  z-index: 2;
                  width: 60%;
                  max-height: 100%;
                  border-radius: 50%;
                  margin-bottom: 4%;
                  top: 0px;
                  left: 0px;
                "
                justify="center"
                @load="oload"
              />
            </div>
          </v-col>
        </v-row>
        <br />
        <br />
        <h4 style="color: #005677">
          <h1>
            {{ selectedleader.firstName + " " + selectedleader.lastName }}
          </h1>
          <h3>
            {{ selectedleader.position }}
          </h3>
        </h4>
        <div
          style="color: #005677"
          v-html="selectedleader['personal_description']"
        ></div>
        <br />
        <v-btn
          icon
          class="md-4 pa-4"
          :href="selectedleader.Facebook"
          target="_blank"
          v-show="selectedleader.Facebook != '0'"
        >
          <v-icon color="#005677"> mdi-facebook </v-icon>
        </v-btn>
        <v-btn
          icon
          class="md-4 pa-1 mx-4"
          :href="selectedleader.instagram"
          v-show="selectedleader.instagram != '0'"
          target="_blank"
        >
          <v-icon dark color="#005677"> mdi-instagram </v-icon>
        </v-btn>
        <v-btn
          icon
          class="md-4 pa-4"
          :href="selectedleader.Linkedin"
          v-show="selectedleader.Linkedin != '0'"
          target="_blank"
        >
          <v-icon color="#005677"> mdi-linkedin </v-icon>
        </v-btn>
      </b-overlay>
    </b-modal>
  </div>
</template>
<script>
import ourTeamHeader from "../components/ourTeamHeader.vue";
export default {
  name: "my-team-header",
  inject: ["teamRepo"],
  components: { ourTeamHeader },
  data() {
    return {
      image: require("../assets/images/Circle-07-13.svg"),
      team: [
        {
          firstName: "N1zar",
          lastName: "Salloum",
          position: "leader",
          photos: [],
          facebook: "",
          twitter: "",
          instagram: "",
        },
      ],
      selectedleader: { photos: [""] },
      show: false,
    };
  },
  mounted() {
    this.teamRepo.all().then((response) => (this.team = response["data"]));
    this.$store.commit("setoverlay", { overlay: true });
  },
  computed: {
    origin() {
      return this.$store.state.origin;
    },
    lang() {
      return this.$store.state.lang;
    },
    teammore() {
      return this.$store.state.teammore;
    },
  },
  methods: {
    oload() {
      this.$store.commit("setoverlay", { overlay: false });
    },
    overlay() {
      return this.$store.state.overlay;
    },
    showServices(i) {
      this.selectedleader = this.team[i];
      this.$bvModal.show("service-modal");
      this.show = false;
    },
  },
};
</script>
