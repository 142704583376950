<template>
  <div
    v-if="im3"
    :style="{
      height: '100vh',
      textAlign: 'center',

      backgroundImage: 'url(\'' + origin + im3 + '\')',
      backgroundSize: '100%',
    }"
  >
    <!--height: 100vh; text-align: center; background-image: url(\''+image+'\')' -->
    <v-row justify="center" style="margin-top: 50px; height: 10%">
      <v-col cols="12" sm="3">
        <h1>Our Team</h1>
      </v-col>
    </v-row>
    <v-row
      style="padding-right: 20px; height: 90%"
      justify="center"
      align="end"
    >
      <v-col cols="12" sm="6" style="padding: 0px">
        <img :src="origin + im2" style="height 50%;" class="left" />
      </v-col>
      <v-col cols="12" sm="6" style="padding: 0px">
        <img :src="origin + im1" style="height 50%;" class="right" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "ourTeamHeader",
  data() {
    return {
      image: require("../assets/images/png 3 .png"),
      width: window.innerWidth,
      header: [],
    };
  },
  mounted() {
    if (this.width < 430) {
      this.image = "";
    }
  },
  computed: {
    origin() {
      return this.$store.state.origin;
    },
    im1() {
      if (this.$store.state.theader.length > 0) {
        return this.$store.state.theader.filter((e) => e.PhotoTeam == 192)[0]
          .src;
      } else return 0;
    },
    im2() {
      if (this.$store.state.theader.length > 0) {
        return this.$store.state.theader.filter((e) => e.PhotoTeam == 193)[0]
          .src;
      } else return 0;
    },
    im3() {
      if (this.$store.state.theader.length > 0) {
        return this.$store.state.theader.filter((e) => e.PhotoTeam == 194)[0]
          .src;
      } else return 0;
    },
  },
};
</script>
<style scoped>
.right {
  z-index: 3;
  width: 102%;
  margin-right: 40px;
}
.left {
  width: 114%;
}
@media screen and (max-width: 480px) {
  .right {
    z-index: 0;
    width: 106%;
    margin-right: 40 px;
    position: relative;
    top: -160px;
  }
  .left {
    width: 114%;
    position: relative;
    transform: rotateY(180deg);
    top: 287px !important;
    z-index: 2;
  }
}
</style>
