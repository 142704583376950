var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('our-team-header'),_c('v-container',[_c('v-row',{attrs:{"justify":"center"}},_vm._l((_vm.team),function(leader,index){return _c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.overlay),expression:"overlay"}],key:leader.OurTeamID,attrs:{"cols":"12","sm":"4","data-aos":"zoom-in","data-aos-anchor-placement":"bottom-bottom"}},[_c('div',{style:({
            backgroundImage: 'url(\'' + _vm.image + '\')',
            backgroundSize: '92%',
            backgroundPosition: 'bottom',
            width: '100%',
            paddingBottom: '14px',
          })},[_c('img',{staticStyle:{"position":"relative","z-index":"2","width":"80%","max-height":"100%","border-radius":"50%","margin-left":"10%","top":"0px","left":"0px"},attrs:{"contain":"","src":_vm.origin + leader.photos[0],"justify":"center"},on:{"load":_vm.oload}})]),_c('div',{staticStyle:{"text-align":"center"}},[_c('h1',[_vm._v(_vm._s(leader.firstName + " " + leader.lastName))]),_c('h3',[_vm._v(_vm._s(leader.position))]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(leader.Facebook != '0'),expression:"leader.Facebook != '0'"}],staticClass:"md-4 pa-4",attrs:{"icon":"","href":leader.Facebook,"target":"_blank"}},[_c('v-icon',{attrs:{"color":"#005677"}},[_vm._v(" mdi-facebook ")])],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(leader.instagram != '0'),expression:"leader.instagram != '0'"}],staticClass:"md-4 pa-1 mx-4",attrs:{"icon":"","href":leader.instagram,"target":"_blank"}},[_c('v-icon',{attrs:{"dark":"","color":"#005677"}},[_vm._v(" mdi-instagram ")])],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(leader.Linkedin != '0'),expression:"leader.Linkedin != '0'"}],staticClass:"md-4 pa-4",attrs:{"icon":"","href":leader.Linkedin,"target":"_blank"}},[_c('v-icon',{attrs:{"color":"#005677"}},[_vm._v(" mdi-linkedin ")])],1)],1),_c('div',{staticStyle:{"text-align":"center"}},[_c('v-btn',{attrs:{"color":"#005677","outlined":""},on:{"click":function($event){return _vm.showServices(index)}}},[_vm._v(" "+_vm._s(_vm.teammore[_vm.lang])+" ")])],1)])}),1)],1),_c('b-modal',{attrs:{"id":"service-modal","hide-footer":true,"size":"l"}},[_c('b-overlay',{staticStyle:{"text-align":"center"},attrs:{"show":_vm.show}},[_c('v-row',{staticStyle:{"width":"100%"},attrs:{"justify":"center"}},[_c('v-col',[_c('div',{style:({
              backgroundImage: 'url(\'' + _vm.image + '\')',
              backgroundSize: '72%',
              backgroundPosition: 'bottom',
              width: '100%',
            })},[_c('img',{staticStyle:{"position":"relative","z-index":"2","width":"60%","max-height":"100%","border-radius":"50%","margin-bottom":"4%","top":"0px","left":"0px"},attrs:{"contain":"","src":_vm.origin + _vm.selectedleader.photos[0],"justify":"center"},on:{"load":_vm.oload}})])])],1),_c('br'),_c('br'),_c('h4',{staticStyle:{"color":"#005677"}},[_c('h1',[_vm._v(" "+_vm._s(_vm.selectedleader.firstName + " " + _vm.selectedleader.lastName)+" ")]),_c('h3',[_vm._v(" "+_vm._s(_vm.selectedleader.position)+" ")])]),_c('div',{staticStyle:{"color":"#005677"},domProps:{"innerHTML":_vm._s(_vm.selectedleader['personal_description'])}}),_c('br'),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedleader.Facebook != '0'),expression:"selectedleader.Facebook != '0'"}],staticClass:"md-4 pa-4",attrs:{"icon":"","href":_vm.selectedleader.Facebook,"target":"_blank"}},[_c('v-icon',{attrs:{"color":"#005677"}},[_vm._v(" mdi-facebook ")])],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedleader.instagram != '0'),expression:"selectedleader.instagram != '0'"}],staticClass:"md-4 pa-1 mx-4",attrs:{"icon":"","href":_vm.selectedleader.instagram,"target":"_blank"}},[_c('v-icon',{attrs:{"dark":"","color":"#005677"}},[_vm._v(" mdi-instagram ")])],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedleader.Linkedin != '0'),expression:"selectedleader.Linkedin != '0'"}],staticClass:"md-4 pa-4",attrs:{"icon":"","href":_vm.selectedleader.Linkedin,"target":"_blank"}},[_c('v-icon',{attrs:{"color":"#005677"}},[_vm._v(" mdi-linkedin ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }